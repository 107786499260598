import { ApplicationBase } from "./app";
import { DeviceInfo } from "./common/common";
import { LocalizationManager } from "./common/localizationManager";
import DeviceConnector from "./utils/deviceConnector";

export class Context {
  private static instance: Context;
  private app: ApplicationBase;
  private deviceInfo: DeviceInfo;
  private deviceConnector: DeviceConnector;
  private localizationManager: LocalizationManager;

  private constructor() {}

  public static getInstance(): Context {
    if (!Context.instance) {
      Context.instance = new Context();
    }

    return Context.instance;
  }

  public static registerApplication(app: ApplicationBase) {
    const instance = Context.getInstance();
    if (instance.app) throw new Error("Application mischmash.");

    return (instance.app = app);
  }

  public static getApplication(): ApplicationBase {
    const instance = Context.getInstance();
    return instance.app;
  }

  public static showMessage(message: string) {
    alert(message);
  }

  public static get DeviceInfo(): DeviceInfo {
    if (!this.getInstance().deviceInfo) {
      this.getInstance().deviceInfo = new DeviceInfo();
    }

    return this.getInstance().deviceInfo;
  }

  public static get DeviceConnector(): DeviceConnector {
    if (!this.getInstance().deviceConnector) {
      this.getInstance().deviceConnector = new DeviceConnector();
    }
    return this.getInstance().deviceConnector;
  }
  public static get LocalizationManager(): LocalizationManager {
    if (!this.getInstance().localizationManager) {
      this.getInstance().localizationManager = new LocalizationManager();
    }
    return this.getInstance().localizationManager;
  }
}

// Lokalizace textu
export function __(key: string) {
  const keyResolved = Context.LocalizationManager.resolve(key);
  return keyResolved ? keyResolved : key;
}
