import React, { useRef, useContext, useEffect } from "react";
import { Context } from "../../appcontext";
import { UpdateFormattableInput, UpdateWebContentEditor } from "../../common/communication.base";
import { NclWebContentEditor } from "../../common/components.ncl";
import { AppContext } from "../../context";
import { useServerState } from "../hooks";
import { WithContextPlacementProps } from "../k2hoc";
import "./WebContentEditor.scss";
import { getAttributes, getTheme } from "../../common/common";

const K2WebContentEditor = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclWebContentEditor, UpdateWebContentEditor, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclWebContentEditor
  );
  const editorData: { EditMode: boolean; FieldData: { Changed: boolean; Name: string; Value: string }[] } = JSON.parse(window.atob(data.Json));

  const handleChange = (name: string, value: string) => {
    control.change(name, value);
  };

  return (
    <div className="wce" key={data.CurrentUrl}>
      {editorData.FieldData.map((field, i) => (
        <Editor key={i} name={field.Name} text={field.Value} onChange={handleChange} inEditMode={editorData.EditMode} />
      ))}
    </div>
  );
};

export default K2WebContentEditor;

interface EditorProps {
  text: string;
  name: string;
  inEditMode: boolean;
  data?: UpdateFormattableInput;
  onChange: (name: string, value: string) => void;
  initRef?: (element: HTMLIFrameElement) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
}

export const Editor = (props: EditorProps) => {
  const appContext = useContext(AppContext);
  const element = useRef<HTMLIFrameElement>(null);
  const timer = useRef<any>(-1);
  const subContent = useRef<string>(null);
  const focus = useRef<boolean>(null);

  //Pro WebContentEditor property vnitř
  useEffect(() => {
    handleFeedContent();
  }, [props.text]);

  const onChange = (content: string) => {
    if (!content.startsWith("<html>")) {
      content = "<html>" + content + "</html>";
    }

    subContent.current = content;
    props.onChange(props.name, content);
  };

  const handleFeedContent = () => {
    if (getTheme() === "dark") {
      if (element.current?.contentWindow?.document.querySelector("html"))
        element.current.contentWindow.document.querySelector("html").style.colorScheme = "dark";
    }

    const htmlWrap = element.current?.contentWindow?.document.querySelector(".html_wrap");

    if (htmlWrap == null) return;

    const text = props.text.replace(/[\r\n]/g, "");
    // Pokud je rozdíl mezi odeslaným change a příchozím textem
    // Pokud není focus a je rozdíl mezi realným content a príchozím textem (např. změna jazyka)
    if (subContent.current !== text || (htmlWrap.innerHTML !== text && !focus.current)) {
      htmlWrap.innerHTML = text;
    }
  };

  const handleLoad = () => {
    element.current?.contentWindow?.document.addEventListener("keydown", (e) => {
      if (e.key.match(/^[a-z]$/i) || e.code.includes("Numpad") || e.code.includes("Arrow") || ["Enter", "Delete", "Backspace"].includes(e.key)) {
        return;
      }

      Context.getApplication().keyDown(e);
    });

    if (!props.inEditMode) return;

    const htmlWrap = element.current?.contentWindow?.document.querySelector(".html_wrap");

    if (htmlWrap == null) return;

    handleFeedContent();
    htmlWrap.addEventListener("input", handleInput);
    htmlWrap.addEventListener("focus", (e: FocusEvent) => {
      focus.current = true;
      props.onFocus(e);
    });
    htmlWrap.addEventListener("blur", (e: FocusEvent) => {
      focus.current = false;
      props.onBlur(e);
    });
  };

  const handleInput = (e: Event) => {
    const target = e.currentTarget as HTMLDivElement;

    if (target.tagName !== "DIV") return;

    if (timer.current > -1) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (target.getAttribute("k2editortype") === "code") {
        onChange((element.current?.contentWindow as any).unescapeHTML((target.childNodes[1] as HTMLDivElement).innerHTML));

        return;
      }

      onChange(target.innerHTML);
    }, 300);
  };

  const refCallback = (el: Element) => {
    if (el instanceof HTMLIFrameElement) {
      element.current = el;
      props.initRef?.(el);
    }
  };

  return (
    <iframe
      title="Editor"
      data-k2-test-id={props.name}
      className={`wce_iframe${props.inEditMode ? " edit" : ""}`}
      srcDoc={props.inEditMode ? undefined : props.text}
      src={props.inEditMode ? "./editable.html" : undefined}
      ref={refCallback}
      onLoad={handleLoad}
      style={{ pointerEvents: appContext?.pointerEvents }}
      {...getAttributes(props.data)}
    />
  );
};
